import React, { Component } from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import Prductslide1 from './Prductslide1';
import { withRouter } from "./withRouter";
import Mydineorderdetails from './Mydineorderdetails';

class MyDineorders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      user_id: localStorage.getItem('admin_id'),
      
    };
  }

 

  render() {
    return (
      <div className="box">
        <Header_home />
        <div className='myorders'>
          <div className='my_ord'>
           <Mydineorderdetails />
          </div>
        </div>
        <br></br>
        <br></br>
        <div className='sell_best_product'>
          <h2 className="">Similar Products</h2>
          <Prductslide1 />
          <br></br>
          <br></br>
          <br></br>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(MyDineorders);
