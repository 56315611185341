import React from "react";
import { withRouter } from "./withRouter";
import './style.css';
import Header from "./Header";
import Footer from './Footer';
import axios from 'axios';
import validator from 'validator';

import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBv_JlUvJV02au2X36RWnrkVHGfp470Y44",
  authDomain: "tabemono-d723e.firebaseapp.com",
  projectId: "tabemono-d723e",
  storageBucket: "tabemono-d723e.appspot.com",
  messagingSenderId: "934425898508",
  appId: "1:934425898508:web:8b0fb5367ffd5a4ad48829",
  measurementId: "G-X999YN02LN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      data: [],
      prescription: 'no', search: '', suc_cls: '',
      errors: {}, openModal: false, pic: '', fileName: '', email: '', email1: '', password: '', showPassword: false,
      showGooglePopup: false, selectedEmail: '', emailError: '', passwordError: ''
    };

    this.onChange_email = this.onChange_email.bind(this);
    this.onChange_email1 = this.onChange_email1.bind(this);
    this.onChange_password = this.onChange_password.bind(this);
    this.loginfun = this.loginfun.bind(this);
    this.signuppage = this.signuppage.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onChange_email2 = this.onChange_email2.bind(this);
    this.loginfun1 = this.loginfun1.bind(this);
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    this.handleGoogleLoginClick = this.handleGoogleLoginClick.bind(this);
    this.handleEmailSelection = this.handleEmailSelection.bind(this);
    this.handleGoogleLoginSubmit = this.handleGoogleLoginSubmit.bind(this);
  }

  signuppage() {
    this.props.navigate('/Signup');
  }

  onChange_email(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChange_email1(e) {
    this.setState({
      email1: e.target.value
    });
  }

  onChange_password(e) {
    this.setState({
      password: e.target.value
    });
  }

  loginfun1() {
    const email = this.state.email2;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email === "") {
      alert("Email Id is empty... please try with Email Id");
    } else if (!emailRegex.test(email)) {
      alert("Invalid email format. Please enter a valid email address.");
    } else {
      alert("Kindly check you email and set new password...");
      window.location.reload();
    }
  }

  onChange_email2(e) {
    this.setState({
      email2: e.target.value
    });
  }

  togglePasswordVisibility() {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }

  toggleModal() {
    this.setState(prevState => ({
      openModal: !prevState.openModal
    }));
  }

  // handleGoogleLoginClick() {
  //   this.setState({ showGooglePopup: true });
  // }

  handleGoogleLoginClick() {
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;

        console.log('User Info:', user.email);
 
        axios({
          method: 'post',
          url: this.state.api_url + '/adminlogin_front',
          data: { 'email': user.email}
        })
          .then(res => {
            if (res.data.status === "false") {
              alert("Invalid email Id or password");
            } else {
              if (res.data.admin_id.length === 0) {
                alert("Invalid email Id or password")
              } else {
                localStorage.setItem("admin_id", res.data.admin_id[0].id);
                this.props.navigate('/Home');
              }
            }
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);

        console.error('Error during sign in:', errorMessage);
      });
  }

  handleEmailSelection(e) {
    this.setState({ selectedEmail: e.target.value });
  }

  handleGoogleLoginSubmit() {
    if (this.state.selectedEmail) {
      axios({
        method: 'post',
        url: this.state.api_url + '/adminlogin_front',
        data: { 'email': this.state.selectedEmail, 'password': '123456' }
      })
        .then(res => {
          if (res.data.status === "false") {
            alert("Invalid email Id or password");
          } else {
            if (res.data.admin_id.length === 0) {
             alert("Invalid email Id or password")
            } else {
              localStorage.setItem("admin_id", res.data.admin_id[0].id);
              this.props.navigate('/Home');
            }
          }
        });
    }
  }

  validatePassword() {
    const { email1,  } = this.state;
    let emailError = '';

    if (!email1) {
      emailError = 'Phone Number is required';
    } 

   

    this.setState({ emailError });

    return !(emailError);
  }

  loginfun(e) {
    e.preventDefault();

    const isValid = this.validatePassword();

    if (isValid) {
      axios({
        method: 'post',
        url: this.state.api_url + '/adminlogin_front',
        data: { 'phone_number': this.state.email1}
      })
        .then(res => {
          if (res.data.status === "false") {
            let errors = {};
            const message = res.data.message;
            errors["message"] = message;
            this.setState({
              errors: errors,
              suc_cls: 'errorMsg floatingAlerts1'
            });
            setTimeout(() => {
              this.setState({
                suc_cls: 'errorMsghide'
              });
            }, 3000);
          } else {
            if (res.data.admin_id.length === 0) {
              let errors = {};
              errors["message"] = "Incorrect Username and Password";
              this.setState({
                errors: errors,
                suc_cls: 'errorMsg floatingAlerts1'
              });
            } else {
              localStorage.setItem("admin_id", res.data.admin_id[0].id);
              this.props.navigate('/Home');
            }
          }
        });
    }
  }

  componentDidMount() {
    if (!sessionStorage.getItem('hasReloaded')) {
      sessionStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    } else {
      sessionStorage.removeItem('hasReloaded');
      this.check_login();
    }
  }

  check_login() {
    const login_mail = localStorage.getItem("admin_id");
    console.log(login_mail);

    if (login_mail) {
      this.props.navigate('/Home');
    } else {
      this.props.navigate('/Login');
    }
  }

  render() {
    const emailOptions = [
      'harsanthjack@gmail.com',
      'harsha@gmail.com',
      'harsanth@gmail.com'
    ];

    return (
      <div className="">
        {<Header />}
        <div className="row log_fun">
          <div className="col-md-6 logo_img">
            <img className="group_img" alt="Group" style={{width:"60%"}} src={require('./img/Group.png')} />
          </div>

          <div className="col-md-6 logo_img1">
            <p className="login_now">Login now</p>
            <div className="welcome_ba">Hi, Welcome back 👋</div>
            <br></br>
            <button className="google" onClick={this.handleGoogleLoginClick}>
              <img className="google1" alt="Group" src={require('./img/icons_google.png')} /> Login with Google
            </button>

            <p className="login_email">-------------------- or Login with Mobile Number ---------------------</p>
            <div className={this.state.suc_cls}>{this.state.errors.message}</div>

            <div className="overlap-wrapper">
              <div className="overlap">
                <label className='label_email'>Mobile Number</label>
                <br></br>
                <input required className="input-BG" type="email" id="emai" autoComplete='off' placeholder="   Enter your email id" value={this.state.email1} onChange={this.onChange_email1} />
                {this.state.emailError && <div className="error">{this.state.emailError}</div>}
              </div>
            </div>

            
            <div className="check_one">
              <div>
                <label>
                  <input type="checkbox" checked={this.state.checked} onChange={this.handleChange} />
                  Remember Me
                </label>
              </div>

              <div className="for_pass">
                <a href="javascript:;" onClick={this.toggleModal}>Forgot Password?</a>
              </div>
            </div>


            {this.state.showGooglePopup && (
              <div className='popup-container'>
                <div className="popup">
                  <div className="popup-content">
                    <h4 className="email_for">Login With Google Select an Email ID</h4>
                    <br></br>
                    {emailOptions.map((email, index) => (
                      <div key={index}>
                        <input type="radio" value={email} checked={this.state.selectedEmail === email} onChange={this.handleEmailSelection} />
                        {email}
                      </div>
                    ))}
                    <br></br>
                    <button onClick={this.handleGoogleLoginSubmit} className="continue" style={{ border: "none", marginTop: "20px", marginLeft: "215px", width: "100px" }}>   Submit     </button>
                  </div>
                </div>
              </div>
            )}

            <button onClick={this.loginfun} className="continue" style={{ border: "none" }}>   Submit     </button>
            {/* <p className='not_r' onClick={this.signuppage} >Not registered yet? <span className='signup_c'> Create an account SignUp</span></p> */}
          </div>
        </div>
        {<Footer />}
      </div>
    );
  }
}

export default withRouter(Login);
