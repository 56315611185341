import React, { useState } from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import axios from 'axios';
import Prductslide1 from './Prductslide1';
import { withRouter } from "./withRouter";

class Trackorder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      user_id: localStorage.getItem('admin_id'),
      order_id: localStorage.getItem('trans_order_id'),
      cart_id: localStorage.getItem('trans_cart_id'),
      data: [],
      product: {},
      data1: [],
      order_details: [],
      cart_details: [],
      status: "delivered",
      prescription: 'no',
      search: '',
      suc_cls: '',
      errors: {},
      openModal: false,
      pic: '',
      fileName: '',
      email: '',
      email1: '',
      password: '',
      updateddate: ''
    };
    this.backtoOrders = this.backtoOrders.bind(this);
  }

  formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  }

  order_detailapi() {
    axios({
      method: 'get',
      url: this.state.api_url + '/trackorders/' + this.state.order_id,
    }).then(res => {
      const data = res.data;
      this.setState({
        order_details: data.trackorder_details[0].status,
        updateddate: this.formatDate(data.trackorder_details[0].updateddate)
      });

      axios({
        method: 'get',
        url: this.state.api_url + '/myOrder_cart/' + this.state.cart_id,
      }).then(res => {
        const data1 = res.data;
        this.setState({
          cart_details: data1.cartItems[0],
        });
      });
    });
  }

  componentDidMount() {
    this.order_detailapi();
  }

  backtoOrders() {
    this.props.navigate('/Myorders');
  }

  render() {
    return (
      <div className="box">
        <Header_home />
        <div className='trackorder1'>
          <div className='track_flex'>
            <div className='track_detail_order'>
              <>
                <div className='track_img'>
                  <img src={this.state.cart_details.product_image} alt={this.state.cart_details.product_name} className='or_img_tr' />
                </div>
                <div className='oder_track_detail'>
                  <p className='pro_track'>{this.state.cart_details.product_name}</p>
                  <p className='pro_track1'>{this.state.cart_details.brand_name}</p>
                  {/* <p className='pro_track2'>₹ {this.state.cart_details.price}</p> */}
                  <p className="product-price">
                          {this.state.cart_details.ori_price < this.state.cart_details.price ? (
                          <>
                          <span className='original-price'>₹{this.state.cart_details.price}</span>
                          <span className='final-price'>₹{this.state.cart_details.ori_price}</span>
                          </>
                          ) : (
                          <span className='final-price'>₹{this.state.cart_details.price}</span>
                          )}
                          </p>
                </div>
              </>
            </div>
            <div className='track_no'>
            <h3>
  <b>
    {this.state.order_details === "order_placed" ? "Order Placed" : this.state.order_details} on {this.state.updateddate}
  </b>
</h3>
              <p>Please Remember To Mark Your Order As "Received" in the requests module</p>
              <p>Tracking Number : <b style={{ color: '#7C3A8E' }}>{this.state.order_id}</b></p>
              

              {this.state.order_details !== 'delivered' && this.state.cart_details.store_mobile !=="" && (
                <p>Store Number: <b>{this.state.cart_details.store_mobile}</b></p>
              )}
                    {this.state.order_details !== 'delivered' && this.state.cart_details.agent_mobile && this.state.cart_details.agent_mobile !== "" && (
                    <p>Agent Number: <b>{this.state.cart_details.agent_mobile}</b></p>
                    )}

            
            </div>
          </div>
          <hr></hr>
          <br />
          <br />
          <div className="order-status" style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '50px', marginLeft: '100px' }}>
            <div className={`status ${(this.state.order_details === 'order_placed' || this.state.order_details === 'purchased') ? 'active' : ''}`}>
              </div>
              <p className='pl_de'><b>ORDER PLACED</b></p>
            </div>
            <hr className='status-line' />
            <div style={{ width: '65px' }}>
              <div className={`status ${this.state.order_details === 'dispatched' ? 'active' : ''}`}>
              </div>
              <p className='pl_de'><b>SHIPPED</b></p>
            </div>
            <hr className='status-line' />
            <div style={{ marginRight: '75px' }}>
              <div className={`status ${this.state.order_details === 'delivered' ? 'active' : ''}`}>
              </div>
              <p className='pl_de'><b>DELIVERED</b></p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
        <br />
        <div className='backtoorders'>
          <button className='order_button' onClick={this.backtoOrders}> Back to my orders</button>
        </div>
        <br />
        <br />
        <div className='sell_best_product'>
          <h2 className="">Similar Products</h2>
          <Prductslide1 />
          <br />
          <br />
          <br />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Trackorder);
