import React from 'react';
import './style.css';
import axios from 'axios';
import {withRouter} from "./withRouter";



class Header_signup extends React.Component {

  constructor(props) {
    super(props);
    this.signuppage = this.signuppage.bind(this);       
  }


  signuppage() {
    this.props.navigate('/Login');
  }
  render() {
    return <div className="header1"> 
    
              <div className="row row_head">
              <div className="col-md-6">
              <p className="welcome-to-market">Welcome To TABEMONO Online Store !</p>
              </div>
              <div className="col-md-6">
              <p className="text-wrapper" onClick={this.signuppage}> Login</p>
              </div>
                      
                      
                  </div>
          
  </div>;
  }
}

export default withRouter(Header_signup);




