import React, { useState, useEffect } from "react";
import './Ordercard.css'; // Import your CSS file for styling
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const Mydineorderdetails = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state
  const navigate = useNavigate();

  const trackOrder = (order_id, cart_id) => {
    localStorage.setItem("trans_order_id", order_id);
    localStorage.setItem("trans_cart_id", cart_id);
    navigate("/Trackorder", { replace: true });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const user_id = localStorage.getItem('admin_id');
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/getusersdineorders/' + user_id);
        let filteredCards = response.data.data;

        setCards(filteredCards);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching card data:", error);
        setError(error); // Set error state
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchCards();
  }, []); // Remove unnecessary dependencies for useEffect

  if (loading) {
    return <div>Loading...</div>; // Show loading message while fetching data
  }

  if (error) {
    return <div>Error fetching data: {error.message}</div>; // Show error message if there's an error
  }

  return (
    <div className="order-container">
      <p className='my_or1'><b>My Dining Orders</b></p>
      <hr />
      <div className="orders-grid">
        {cards && cards.map((p) => (
          <div className="order-card1" key={p._id}>
            <div className="order-info">
              <h3>{p.store_name}</h3>
              <p><strong>Date:</strong> {formatDate(p.date)}</p>
              <p><strong>Meal:</strong> {p.meal}</p>
              <p><strong>Timeslot:</strong> {p.timeslot}</p>
              <p><strong>Guests:</strong> {p.guests}</p>
              <p><strong>Status:</strong> {p.status}</p>
            </div>
          </div>
        ))}
      </div>
      <style jsx>{`
      /* General container */
      .order-container {
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      }
      
      .my_or1 {
        font-size: 24px;
        text-align: center;
        color: #333;
      }
      
      .orders-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }
      
      .order-card {
        background-color: #fff;
        border-radius: 8px;
        padding: 15px;
        border: 1px solid #ddd;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
      }
      
      .order-card:hover {
        transform: translateY(-5px);
      }
      
      .order-info {
        margin-bottom: 15px;
        text-align:left;
      }
      
      .order-info h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #007bff;
      }
      
      .order-info p {
        font-size: 14px;
        margin: 5px 0;
      }
      
      .track-order-btn {
        padding: 10px 15px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
      
      .track-order-btn:hover {
        background-color: #0056b3;
      }
      
       `}</style>
    </div>
  );
};

export default Mydineorderdetails;
