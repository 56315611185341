import React, { useState, useEffect } from "react";
import './Ordercard.css'; // Import your CSS file for styling
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const Storecart = () => {
  const [cards, setCards] = useState([]);
  const navigate = useNavigate();

  const handleDivClick = (id) => {
    localStorage.setItem("store_id", id);
    navigate("/Foods", { replace: true });
  };

  // Fetch liked items for the current user
  useEffect(() => {
    const fetchCards = async () => {
      try {
        const user_id = localStorage.getItem('admin_id');
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/getstores/');
        setCards(response.data.stores);
      } catch (error) {
        console.error("Error fetching liked items:", error);
      }
    };

    fetchCards();
  }, []);

  return (
    <div>
      <h2 className="save_for">Our Hotels ({cards.length})</h2>
      <hr />
      <div className="store-container">
        {cards.map((product) => (
          <div key={product._id} className="order-card1" onClick={() => handleDivClick(product._id)}>
            <div className="product-info">
              <img className="product-image" src={product.image} alt={product.store_name} />
              <div className="product-details">
                <p className="product-name"><b>{product.store_name}</b></p>
                <p style={{color:"#9C9C9C"}}>{product.email}</p>
                <br></br>
                <p style={{color:"#9C9C9C"}}>Address</p>
                <span>{product.add_details}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      {cards.length === 0 && (
        <p className="emptycart">Store is empty</p>
      )}
      <br />
    </div>
  );
};

export default Storecart;
