import React, { createContext, useContext, useState } from 'react';
import en from './translations/en.json';
import ta from './translations/ta.json';

const translations = { en, ta };

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
    const [language, setLanguage] = useState('en');

    const translate = (key) => {
        return translations[language][key] || key;
    };

    return (
        <TranslationContext.Provider value={{ translate, setLanguage }}>
            {children}
        </TranslationContext.Provider>
    );
};

export const useTranslation = () => useContext(TranslationContext);
