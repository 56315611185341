import React, { useState, useEffect } from "react";
import './Autocomplete.css';
import axios from "axios";
import { useNavigate } from 'react-router-dom';


const Autocomplete = () => {
  const [inputValue, setInputValue] = useState('');
  const [product, setProducts] = useState([]);
  const navigate = useNavigate();

  const handleChange = async (e) => {
    const { value } = e.target;
    setInputValue(value);
    
    // Fetch suggestions from the API based on the input value
    const api_url = localStorage.getItem('api_url');
    try {
      const response = await axios.post(api_url + '/admingetProducts_autocomplete', {
        search_term: value
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (response.data.status === "true") {
        const products = response.data.products;
        // Filter suggestions based on the input value (this step might be redundant now)
        const filteredProducts = products.filter((product) =>
          product.product_name.toLowerCase().includes(value.toLowerCase())
        );
        setProducts(filteredProducts);
      } else {
        // Handle no products found
        setProducts([]);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      setProducts([]);
    }
  };
  
  
  const handleSelectSuggestion = (product_name,id) => {
    setInputValue(product_name);
    setProducts([]);

      localStorage.setItem("product_id", id);
      navigate("/Detail_page", { replace: true });
      window.location.reload();
    

  };
  
  return (
    <div className="autocomplete">
      <input
        type="text"
        className='autp_c'
        value={inputValue}
        onChange={handleChange}
        placeholder="Search for Cuisine or a Dish"
      />
      <ul className="suggestions">
        {product.map((product, index) => (
          <li key={index} onClick={() => handleSelectSuggestion(product.product_name,product.id)}>
            {product.product_name}
          </li>
        ))}
      </ul>

      {/* <button className='com_btn1 '>SEARCH</button> */}
    </div>
  );
  
};

export default Autocomplete;
