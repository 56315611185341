import React, { useState, useEffect } from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import Prductslide from './Prductslide';
import Userlikecart from './Userlikecart';
import { useTranslation } from './TranslationContext';

const Likecart = () => {
  const [data, setData] = useState([]);
  const [likecarts, setLikecarts] = useState([]);
  const [prescription, setPrescription] = useState('no');
  const [search, setSearch] = useState('');
  const [sucCls, setSucCls] = useState('');
  const [errors, setErrors] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [pic, setPic] = useState('');
  const [fileName, setFileName] = useState('');
  const [email, setEmail] = useState('');
  const [email1, setEmail1] = useState('');
  const [password, setPassword] = useState('');

  const api_url = localStorage.getItem('api_url');
  const user_id = localStorage.getItem('admin_id');

  const { translate } = useTranslation(); // You can now use the `translate` function.

  useEffect(() => {
    // componentDidMount logic here
  }, []);

  return (
    <div className="box">
      <Header_home />
      <br />
      <br />
      <div className="social_container">
        <div className="rating_detail_page">
          <div className="rating"></div>
        </div>
      </div>
      <div className="container">
        <div className="price_amount5">
          <Userlikecart />
          <br />
        </div>
      </div>
      <br />
      <br />
      <div className="sell_best_product">
        <h2>{translate('recently')}</h2>
        <Prductslide />
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </div>
  );
};

export default Likecart;
