import React, { useState, useCallback, useRef } from 'react';
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import axios from "axios";

const libraries = ['places'];

const MapComponent = ({ onLocationSelect }) => {
  const [map, setMap] = useState(null);
  const [markerPosition, setMarkerPosition] = useState({ lat: -34.397, lng: 150.644 }); // Default location
  const [address, setAddress] = useState('');
  const [placeName, setPlaceName] = useState('');
  const autocompleteRef = useRef(null);
  const geocoder = useRef(null);

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);

    // Initialize the Geocoder after the map is loaded
    if (!geocoder.current && window.google) {
      geocoder.current = new window.google.maps.Geocoder();
    }
  }, []);

  const onAutocompleteLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry) {
        const location = place.geometry.location;
        const position = { lat: location.lat(), lng: location.lng() };
        setMarkerPosition(position);
        setAddress(place.formatted_address); // Set the full address
        setPlaceName(place.name || ''); // Set the place name if available
        map.panTo(position); // Pan the map to the new location

        if (onLocationSelect) {
          onLocationSelect(position, place.formatted_address);
        }
      }
    }
  };

  // Reverse Geocoding to get address when marker is dragged
  const reverseGeocode = (latLng) => {
    if (geocoder.current) {
      geocoder.current.geocode({ location: latLng }, (results, status) => {
        if (status === "OK" && results[0]) {
          setAddress(results[0].formatted_address);
          if (onLocationSelect) {
            onLocationSelect(latLng, results[0].formatted_address);
          }
        } else {
          console.error("Geocoding failed: " + status);
        }
      });
    }
  };

  const onMarkerDragEnd = (e) => {
    const newLat = e.latLng.lat();
    const newLng = e.latLng.lng();
    const newPosition = { lat: newLat, lng: newLng };
    setMarkerPosition(newPosition);

    // Reverse geocode to get the new address for the dragged location
    reverseGeocode(newPosition);
  };

  const handleSaveLocation = async () => {
    if (markerPosition) {
      const place = placeName;
      const address_detail = address;
      const lat = markerPosition.lat + ',' + markerPosition.lng;

      try {
        const user_id = localStorage.getItem('admin_id');
        const api_url = localStorage.getItem('api_url');

        const response = await axios.post(api_url + '/addaddress', {
          user_id,
          place,
          address_detail,
          lat
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          transformRequest: [(data) => {
            const params = new URLSearchParams();
            for (const key in data) {
              params.append(key, data[key]);
            }
            return params.toString();
          }],
        });

        const check_res = response.data.status;

        if (check_res) {
          alert("Success! Your address has been added.");
          window.location.reload();
        }
      } catch (error) {
        console.error('Error saving location:', error);
        alert('An error occurred while saving location');
      }
    }
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyCkRSn-ZXBqRN5Qm2cDL3Wz1hz2xftOft4" libraries={libraries}>
      <GoogleMap
        onLoad={onLoad}
        mapContainerStyle={{ width: '100%', height: '400px' }}
        center={markerPosition} // Default or current marker position
        zoom={10}
      >
        <Autocomplete
          onLoad={onAutocompleteLoad}
          onPlaceChanged={onPlaceChanged}
        >
          <input
            type="text"
            placeholder="Search location"
            style={{
              boxSizing: 'border-box',
              border: '1px solid transparent',
              width: '240px',
              height: '32px',
              padding: '0 12px',
              borderRadius: '3px',
              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
              fontSize: '14px',
              outline: 'none',
              textOverflow: 'ellipsis',
              position: 'absolute',
              left: '50%',
              top: '10px',
              transform: 'translateX(-50%)',
            }}
          />
        </Autocomplete>

        {markerPosition && (
          <Marker
            position={markerPosition}
            draggable={true} // Make the marker draggable
            onDragEnd={onMarkerDragEnd} // Handle when dragging stops
          />
        )}

        {markerPosition && (
          <button onClick={handleSaveLocation} className='btn btn-primary' style={{ position: 'absolute', borderRadius:'5px', bottom: '20px', left: '50%', transform: 'translateX(-50%)' }}>
            Save Location
          </button>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
