import React, { useState, useEffect } from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import axios from 'axios';
import Prductslide1 from './Prductslide1';
import Profilesidebar from './Profilesidebar';
import { useTranslation } from './TranslationContext';

const Profile = () => {
  const [product, setProduct] = useState({});
  const [api_url] = useState(localStorage.getItem('api_url'));
  const [user_id] = useState(localStorage.getItem('admin_id'));

  const { translate } = useTranslation(); // Using useTranslation hook in functional component

  const product_detailapi = () => {
    axios({
      method: 'get',
      url: `${api_url}/admingetProduct_frontend/${user_id}`,
    }).then(res => {
      const data = res.data;
      setProduct(data.product);
    });
  };

  useEffect(() => {
    product_detailapi();
  }, []); // Runs once after the component mounts

  return (
    <div className="box">
      <Header_home />
      <Profilesidebar />
      <br />
      <br />
      <div className="sell_best_product">
        <h2>{translate('similar')}</h2>
        <Prductslide1 />
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
