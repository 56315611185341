import React, { useState } from 'react';
import './StarRating.css'; // Import the CSS file for styling
import { useTranslation } from './TranslationContext';


const Detail_rating = () => {
  const [rating, setRating] = useState(0); // State to store the current rating

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating); // Update the rating state when a star is clicked
  };
  const { translate } = useTranslation();
  return (
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((star) => (
        <span
          key={star}
          className={star <= rating ? 'star selected' : 'star'} // Apply selected class based on rating
          onClick={() => handleStarClick(star)} // Handle click event to update rating
        >
          &#9733; {/* Render a star character */}
        </span>
      ))}
       <p className='review_count'> {rating} {translate('review')}</p>
    </div>
  );
};

export default Detail_rating;
