import React, { useState, useEffect } from 'react';

const LocationComponent = () => {
  const [location, setLocation] = useState({ lat: null, lon: null, address: null });
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check if Geolocation is available
    if ("geolocation" in navigator) {
      // Request the current position
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          // Success callback
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          setLocation({ lat, lon });

          // Fetch the address
          try {
            const address = await reverseGeocode(lat, lon);
            setLocation((prevLocation) => ({ ...prevLocation, address }));
          } catch (e) {
            setError("Unable to fetch address.");
          }
        },
        (error) => {
          // Error callback
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not available in this browser.");
    }
  }, []);

  const reverseGeocode = async (lat, lon) => {
    const apiKey = 'AIzaSyCkRSn-ZXBqRN5Qm2cDL3Wz1hz2xftOft4';
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`);
    const data = await response.json();
    if (data.results.length > 0) {
      return data.results[0].formatted_address;
    } else {
      throw new Error("Address not found");
    }
  };

  return (
    <div>
      <h2>Your Location</h2>
      {error && <p>Error: {error}</p>}
      {location.lat && location.lon ? (
        <>
          <p>Latitude: {location.lat}</p>
          <p>Longitude: {location.lon}</p>
          {location.address && <p>Address: {location.address}</p>}
        </>
      ) : (
        <p>Fetching location...</p>
      )}
    </div>
  );
};

export default LocationComponent;
