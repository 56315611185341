import React, { useState } from 'react';
import './style.css'; // Import your CSS file

function Popup() {
    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    return (
        <div className="container">
            <button onClick={togglePopup}>Open Popup</button>
            {showPopup && (
                <div className="popup">
                    <div className="popup-inner">
                        <h2>Popup Content</h2>
                        <p>This is the content of the popup.</p>
                        <button onClick={togglePopup}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Popup;
