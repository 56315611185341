import React, { useState, useEffect } from "react";
import './Ordercard.css'; // Import your CSS file for styling
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Timeslots from './Timeslots';
import Storeuser_review from './Storeuser_review';


const Diningcart = () => {
  const [cards, setCards] = useState([]);
  const [cards1, setCards1] = useState([]);

  const [cards2, setCards2] = useState([]);
  const [cards3, setCards3] = useState([]);
  const [cards4, setCards4] = useState([]);
  const [activeTab, setActiveTab] = useState('tab1'); // State to track the active tab
  const [location, setLocation] = useState({ lat: 0, lng: 0 }); // Default location
  const [isCopied, setIsCopied] = useState(false); // State to track if the URL is copied


  const navigate = useNavigate();
  const containerStyle = {
    width: '100%',
    height: '400px'
  };

  const handleDivClick = (id) => {
    localStorage.setItem("store_id", id);
    navigate("/Foods", { replace: true });
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Update the active tab when a tab is clicked
  };

  const handleGetDirections = () => {
    const { lat, lng } = location;
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    window.open(googleMapsUrl, '_blank');  // Open in a new tab
  };

  const handleCopyLocation = () => {
    const { lat, lng } = location;
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;

    navigator.clipboard.writeText(googleMapsUrl)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset the copied state after 2 seconds
      })
      .catch((error) => {
        console.error('Failed to copy:', error);
      });
  };
 
  // Fetch liked items for the current user
  useEffect(() => {
    const fetchCards = async () => {
      try {
        const store_id = localStorage.getItem('store_id');
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/getdinestorefoods/'+store_id);
        setCards(response.data.fooddetails);
        setCards1(response.data.store_details);
        setCards2(response.data.store_timeslots);
        setCards3(response.data.image);
        setCards4(response.data.images);

      // Get lat and lng from the API response
      const [lat, lng] = response.data.store_details.lat.split(',').map(Number);

      // Set the dynamic location state
      setLocation({ lat, lng });

      } catch (error) {
        console.error("Error fetching liked items:", error);
      }
    };

    fetchCards();
  }, []);

  const filteredTimeSlots = cards2.filter(slot => slot.meal === activeTab);


  return (
    <div>
      
      <div className="dinging_detail">
      <div className='hea_img5'>
        <img className='hea_img2' src={cards3}  />
        <img className='hea_img2' src={cards3}  />
      </div>
      <div style={{textAlign:"left"}}>
        <h2>{cards1.store_name}</h2> 
        <p>{cards1.email}</p> 
        <p>{cards1.address_detail}</p>
        <p style={{color:"red"}}>{cards1.status === "1"? "Open Now":"closed"}<span style={{color:"#000000"}}>(Today)</span></p> 
      </div>
      <br></br>

      <div className="tabs1">
        <div
          className={activeTab === 'tab1' ? 'tab active' : 'tab'}
          onClick={() => handleTabClick('tab1')}
        >
          Overview
        </div>
        <div
          className={activeTab === 'tab2' ? 'tab active' : 'tab'}
          onClick={() => handleTabClick('tab2')}
        >
         Review
        </div>
        <div
          className={activeTab === 'tab3' ? 'tab active' : 'tab'}
          onClick={() => handleTabClick('tab3')}
        >
          Photo
        </div>
        <div
          className={activeTab === 'tab4' ? 'tab active' : 'tab'}
          onClick={() => handleTabClick('tab4')}
        >
          Menu
        </div>
        <div
          className={activeTab === 'tab5' ? 'tab active' : 'tab'}
          onClick={() => handleTabClick('tab5')}
        >
          Book Dining
        </div>
      </div>

      <div className="tab-content1">
        {activeTab === 'tab1' && (
          <div>
            <div className="detail_tab3">
              <div className="dineover">
                <div className="dinemeet">
                <p><b>Menu</b></p>
                {cards4.map((p) => (
                <img className='hea_img7' src={p.images} />
                ))}
                <p>Food Menu</p>
                  <br></br>  
                  <h3><b>People Say This Place is Known For</b></h3>
                  <p>Reasonable Rate,Excellent Location, Wonderfull Presentation, Good Quantity</p>  
                  <p>Good Delivery, Nice Taste</p>   
                  <br></br>  
                  <h3><b>More Info</b></h3>    
                  <p> Home Delivery, Indoor Seating, takeaway Available</p>     
                  </div>
                   <div className="dinecall">
                    <p><b>Call</b></p>
                    <p>{cards1.phoneno}</p>
                    <br></br>
                    <p><b>Directions</b></p>
                      <LoadScript googleMapsApiKey="AIzaSyCkRSn-ZXBqRN5Qm2cDL3Wz1hz2xftOft4"> {/* Insert your API key here */}
                      <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={location}
                      zoom={15} // Adjust the zoom level
                      >
                      {/* Marker to point the location */}
                      <Marker position={location} />
                      </GoogleMap>
                      </LoadScript>
                      <div style={{display:"flex"}}>
                          <button
                          onClick={handleCopyLocation}
                          style={{ marginTop: '10px', padding: '5px',  color: '#000', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                          >
                          {isCopied ? 'Copied!' : 'Copy'}
                          </button>
                          <button onClick={handleGetDirections} style={{ marginTop: '10px',marginLeft: '10px', padding: '5px', color: '#000', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                          Direction
                       </button>
                       </div>
                       
                       <div>
                    <p>
                    {cards1.address_detail}
                      </p>                  
                        </div>
                
                        </div>
                        </div>

            </div>
          </div>
        )}
        {activeTab === 'tab2' && (
          <div>
            <Storeuser_review/>
          </div>
        )}
        {activeTab === 'tab3' && (
          <div>
             <div className="detail_tab3">
              <div className="dineover">
                <div className="dinemeet">
                {cards4.map((p) => (
                <img className='hea_img7' src={p.images} />
                ))}
          </div>
          </div>
          </div>
          </div>
        )}
        {activeTab === 'tab4' && (
          <div>

<div className="detail_tab3">
              <div className="dineover">
                <div className="dinemeet">
                <p><b>Menu</b></p>
                {cards4.map((p) => (
                <img className='hea_img7' src={p.images} />
                ))}
          </div>
          </div>
          </div>
          </div>
        )}
       {activeTab === 'tab5' && (
          <div>
 

<Timeslots store_timeslots ={cards2}/>
 <br></br>
     
</div>
        )}
      </div>
          
      </div>

    </div>
  );
};

export default Diningcart;
