import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const adminId = localStorage.getItem('admin_id');
  
  if (!adminId) {
    return <Navigate to="/Login" />;
  }

  return children;
};

export default PrivateRoute;
