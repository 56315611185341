import React, { useState, useEffect } from "react";
import axios from "axios";
import SuccessToast from "./SuccessToast";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from './TranslationContext';
import MapComponent from './MapComponent';




function Profilesidebar() {
    const [selectedItem, setSelectedItem] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [cards, setCards] = useState([]);
    const [cards1, setCards1] = useState([]);
    const [cards2, setCards2] = useState([]);
    const [address_detail, setAddress] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [isEditing1, setIsEditing1] = useState(false);
    const [isEditing2, setIsEditing2] = useState(false);
    const navigate = useNavigate();
    const { translate } = useTranslation();
    const [location, setLocation] = useState(null);

    //add address values

const [addressType1, setAddressType] = useState('');
const [district1, setDistrict] = useState('');
const [floorNo1, setFloorNo] = useState('');
const [state1, setState] = useState('');
const [building1, setBuilding] = useState('');
const [country1, setCountry] = useState('');
const [city1, setCity] = useState('');
const [postalCode1, setPostalCode] = useState('');
const [add_id, setAdd_id] = useState('');
const [gender, setGender] = useState('');

const handleGenderChange = (e) => {
    setGender(e.target.value);
};

const handleLocationSelect = (position) => {
    setLocation(position);
    console.log('Selected location:', position);
  };

    const togglePopup = async (id) => {

        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/getaddress/'+id);
        setAddressType(response.data.address1.type);
        setDistrict(response.data.address1.district);
        setFloorNo(response.data.address1.landmark);
        setState(response.data.address1.state);
        setCountry(response.data.address1.country);
        setCity(response.data.address1.area);
        setBuilding(response.data.address1.building);
        setPostalCode(response.data.address1.pincode);
        setAdd_id(response.data.address1._id)
        setIsEditing2(false);
        setShowPopup(!showPopup);
    };

    const togglePopup1 = async () => {
        setIsEditing2(true);
        setAddressType('');
                setDistrict('');
                setFloorNo('');
                setState('');
                setBuilding('');
                setCountry('');
                setCity('');
                setPostalCode('');
                setAdd_id();
        setShowPopup(!showPopup);
    };

    
    const togglePopup_update_add = async () => {

            const address_id = add_id;
            const api_url = localStorage.getItem('api_url');

            const building = building1;
            const area= city1;
            const landmark = floorNo1;
            const  pincode =postalCode1;
            const  type = addressType1;
            const country =country1;
            const state =state1;
            const district =district1;

            const response1 = await axios.post(api_url + '/updateuseraddress', {
                address_id,
                building,
                area, 
                landmark,
                pincode,
                type,
                country,
                state,
                district
              }, {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                },
                transformRequest: [(data, headers) => {
                  const params = new URLSearchParams();
                  for (const key in data) {
                    params.append(key, data[key]);
                  }
                  return params.toString();
                }],
              });
    
              const check_res1 = response1.data.status;
    
              if(check_res1){
                
                toast.success("Success! Your address has been updated.");
                setAddressType('');
                setDistrict('');
                setFloorNo('');
                setState('');
                setBuilding('');
                setCountry('');
                setCity('');
                setPostalCode('');
                setAdd_id();
    
    
                setShowPopup(!showPopup);
              }    

    };
    const togglePopup_save_add = async () => {
        // setShowPopup(!showPopup);
        const user_id = localStorage.getItem('admin_id');
        const api_url = localStorage.getItem('api_url');

               const building = building1;
               const area= city1;
               const landmark = floorNo1;
               const  pincode =postalCode1;
               const  type = addressType1;
               const country =country1;
               const state =state1;
               const district =district1;

        const response1 = await axios.post(api_url + '/adduseraddress', {
            user_id,
            building,
            area, 
            landmark,
            pincode,
            type,
            country,
            state,
            district
          }, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [(data, headers) => {
              const params = new URLSearchParams();
              for (const key in data) {
                params.append(key, data[key]);
              }
              return params.toString();
            }],
          });

          const check_res1 = response1.data.status;

          if(check_res1){
            
            // toast.success("Success! Your address has beed added.");
            setAddressType('');
            setDistrict('');
            setFloorNo('');
            setState('');
            setBuilding('');
            setCountry('');
            setCity('');
            setPostalCode('');


            setShowPopup(!showPopup);
            alert('Success! Your address has beed added.');
            window.location.reload(); 
           
        }
        
    };

    const my_orders = () => {
        navigate("/Myorders", { replace: true });
    }
    const my_orders1 = () => {
        navigate("/MyDineorders", { replace: true });
    }

    const profile_edit = () => {
        setIsEditing(true);
        setIsEditing1(true);
    };

    const profile_update = async () => {
        setIsEditing1(false);
        
     
        const first_name = cards.firstname;
        const last_name = cards.lastname;
        const phone_number = cards.phone_number;
        const email = cards.email;
        // const gender = cards.gender;
        // const password = cards.password;
        const user_id = localStorage.getItem('admin_id');
        const api_url = localStorage.getItem('api_url');


        const response = await axios.post(api_url + '/updateuserdetails', {
            user_id,
            first_name,
            last_name,
            phone_number,
            email,
            gender: gender,
            // password
          }, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [(data, headers) => {
              const params = new URLSearchParams();
              for (const key in data) {
                params.append(key, data[key]);
              }
              return params.toString();
            }],
          });
    
          const check_res = response.data.status;

          if(check_res){
            setIsEditing(false);
            // 
           alert("Success! Your profile has been updated.");
           window.location.reload();
        }
       
    };



    const handleItemClick = (index) => {
        setSelectedItem(index);
    };


    //   setGender(cards.gender);
        //  gender = cards.gender;c
    useEffect(() => {
        const fetchCards = async () => {
          try {
            const user_id = localStorage.getItem('admin_id');
            const api_url = localStorage.getItem('api_url');
            const response = await axios.get(api_url + '/getProfile/'+user_id);
            setCards(response.data.profile);
            setCards1(response.data);
            setCards2(response.data.notification);
            // setAddress(response.data.getaddress);
            setGender(response.data.profile.usergender);

           //get address
           

          } catch (error) {
            console.error("Error fetching card data:", error);
          }
        };
    
        fetchCards();
      }, []);
    
  

    return (
     
 <div  className='full_profile'>      
<div className='profile_details'>
        <div className='profile_name'>
            <div className='img_p'>
            {cards1.profile_image && <img className='pro_img' src={cards1.profile_image.profile_image} />}

            </div> 
            <div className='name_p'>
            <span className='hello_pro'><b>{translate('hello')}</b></span>
            <p className='name_pro'><b>{cards.firstname+' '+cards.lastname}</b></p>
            </div>
        </div>
        <br></br>
        <div className='profile_sidebar'>
            <p className='my_or' onClick={my_orders}><b >{translate('myor')}</b></p>
            <p className='my_or' onClick={my_orders1}><b >My Dine Orders</b></p>
            
            <hr></hr>
            <ul>
             <li>
              <p className='my_acc'><b>{translate('account_setting')}</b></p>
              <ul>
                <li
                    className={selectedItem === 0 ? 'selected submenu' : ' submenu'}
                    onClick={() => handleItemClick(0)}
                >
                   {translate('personal_information')}
                </li>
                <li
                    className={selectedItem === 1 ? 'selected submenu' : 'submenu'}
                    onClick={() => handleItemClick(1)}
                >
                    {translate('manage_address')}
                </li>
                <li
                    className={selectedItem === 2 ? 'selected submenu' : ' submenu'}
                    onClick={() => handleItemClick(2)}
                >
                    {translate('all_notifications')}
                </li>
            </ul>
            </li>
          </ul>            
        </div>
    </div>

    <div className='profile_detail'>
        
    <div className={selectedItem === 0 ? 'personalinformation' : 'personalinformation hide'}>
                    

    <div className='form-group fir_name'>
    <img className={isEditing1 ? 'add_edit1 hide' : 'add_edit1'} onClick={profile_edit} title="edit" src={require('./img/edit_add.png')} />
    <img className={isEditing1 ? 'add_edit1' : 'add_edit1 hide'} onClick={profile_update} title="update" src={require('./img/update.png')} />
            <label className='lable_name'><b>{translate('personal_information')}</b> </label>
            <p></p>
                <div className='name_full_last'>
                    <input disabled={!isEditing} type='text' className='f_name' name="first_name" id="first_name" placeholder='First Name' value={cards.firstname} onChange={(e) => setCards({ ...cards, firstname: e.target.value })}/>
                    <input disabled={!isEditing} type='text' className='l_name' name="last_name" id="last_name" placeholder='Last Name' value={cards.lastname} onChange={(e) => setCards({ ...cards, lastname: e.target.value })}/>

                </div>
            </div>
            <br></br>
            <div className='form-group fir_name'>
            <label className='lable_name'><b>{translate('your_gender')}</b> </label>
            <p></p>
            <div className='name_full_last'>
                <label> <input disabled={!isEditing} type="radio" className='gender_male' value="male" checked={gender === "male"} onChange={handleGenderChange} /> Male </label>
                <label> <input disabled={!isEditing} type="radio" className='gender_female'  value="female" checked={gender === "female"} onChange={handleGenderChange} /> Female </label>
            </div>
            </div>
            <br></br>
            <div className='form-group fir_name'>
            <label className='lable_name'><b>{translate('email_address')}</b> </label>
            <p></p>
                <div className='name_full_last'>
                    <input disabled={!isEditing} type='email' className='f_name' name="email" id="email" placeholder='Enter Email Id' value={cards.email} onChange={(e) => setCards({ ...cards, email: e.target.value })}/>

                </div>
            </div>
            <br></br>
            <div className='form-group fir_name'>
            <label className='lable_name'><b>{translate('mobile_number')}</b> </label>
            <p></p>
                <div className='name_full_last'>
                    <input disabled={!isEditing} type='text' className='f_name' name="mobile" id="mobile" placeholder='Enter Mobile Number' value={cards.phone_number} onChange={(e) => setCards({ ...cards, phone_number: e.target.value })}/>

                </div>
            </div>
            <br></br>
            {/* <div className='form-group fir_name'>
            <label className='lable_name'><b>Password</b> </label>
            <p></p>
                <div className='name_full_last'>
                    <input disabled={!isEditing} type='password' className='f_name' name="old_pass" id="old_pass" placeholder='Old Password' value={cards.password} onChange={(e) => setCards({ ...cards, password: e.target.value })}/>
                    <input disabled={!isEditing} type='password' className='l_name' name="new_pass" id="new_pass" placeholder='New Password' value={cards.password} onChange={(e) => setCards({ ...cards, password: e.target.value })}/>

                </div>
            </div> */}
                </div>
                <div className={selectedItem === 1 ? 'manageaddress' : 'manageaddress hide'}>
                    <div className='form-group fir_name'>
                        <label className='lable_name'><b>{translate('manage_addresses')}</b> </label>
                        <p></p>
                        <button className='addaddress' onClick={() => setLocation(null)} >+ {translate('add_new_address')}</button>
                        <p></p>
                        {Array.isArray(cards1.getaddress) && cards1.getaddress.map((i) => (
                                    <div key={i._id} className='address_block'>
                                        <p><b>place</b> {i.place}</p>
                                        <p><b>address</b> {i.address_detail}</p>
                                    </div>
                                ))}
                                         <div style={{ marginTop: '20px' }}>
                                                <MapComponent onLocationSelect={handleLocationSelect} />
                                            </div>
                                        
                    </div>
                    {/* {showPopup && (
                <div className='popup-container'>
                    <div className='popup'>
                        <div className='popup-inner'>
                        <img className='add_edit1' onClick={togglePopup1} src={require('./img/cancel.png')} />
                            <p className='lable_name1'><b>{translate('add_address')}</b></p>
                             
                            <div className='grid-container'>
                                            <div className='grid-item'>
                                                <div className='form-group fir_name'>
                                                    <label className='lable_name2'><b>Type (Home/office/store)</b></label>
                                                    <div className='name_full_last'>
                                                        <input type='text' className='add_loc_type' name="add_loc_type" id="add_loc_type"  
                                                        value={addressType1}
                                                        onChange={(e) => setAddressType(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='grid-item'>
                                                <div className='form-group fir_name'>
                                                    <label className='lable_name2'><b>District</b></label>
                                                    <div className='name_full_last'>
                                                        <input type='text' className='add_loc_type' name="add_loc_type" id="add_loc_type"  
                                                          value={district1}
                                                          onChange={(e) => setDistrict(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='grid-item'>
                                                <div className='form-group fir_name'>
                                                    <label className='lable_name2'><b>Floor No</b></label>
                                                    <div className='name_full_last'>
                                                        <input type='text' className='add_loc_type' name="add_loc_type" id="add_loc_type" 
                                                          value={floorNo1}
                                                          onChange={(e) => setFloorNo(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='grid-item'>
                                                <div className='form-group fir_name'>
                                                    <label className='lable_name2'><b>State</b></label>
                                                    <div className='name_full_last'>
                                                        <input type='text' className='add_loc_type' name="add_loc_type" id="add_loc_type"  
                                                          value={state1}
                                                          onChange={(e) => setState(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='grid-item'>
                                                <div className='form-group fir_name'>
                                                    <label className='lable_name2'><b>Buliding</b></label>
                                                    <div className='name_full_last'>
                                                        <input type='text' className='add_loc_type' name="add_loc_type" id="add_loc_type"  
                                                          value={building1}
                                                          onChange={(e) => setBuilding(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='grid-item'>
                                                <div className='form-group fir_name'>
                                                    <label className='lable_name2'><b>Country</b></label>
                                                    <div className='name_full_last'>
                                                        <input type='text' className='add_loc_type' name="add_loc_type" id="add_loc_type"  
                                                          value={country1}
                                                          onChange={(e) => setCountry(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='grid-item'>
                                                <div className='form-group fir_name'>
                                                    <label className='lable_name2'><b>City</b></label>
                                                    <div className='name_full_last'>
                                                        <input type='text' className='add_loc_type' name="add_loc_type" id="add_loc_type"  
                                                          value={city1}
                                                          onChange={(e) => setCity(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='grid-item'>
                                                <div className='form-group fir_name'>
                                                    <label className='lable_name2'><b>Postel Code</b></label>
                                                    <div className='name_full_last'>
                                                        <input type='text' className='add_loc_type' name="add_loc_type" id="add_loc_type"  
                                                          value={postalCode1}
                                                          onChange={(e) => setPostalCode(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            
                            <br></br>
                           <div className='save_can_add'>
                                <button className='can_save' onClick={togglePopup1}>Cancel</button>
                                <button className= {isEditing2 ? 'add_save' : 'add_save hide'}  onClick={togglePopup_save_add}>Save</button>
                                <button className= {isEditing2 ? 'add_save hide' : 'add_save'}  onClick={togglePopup_update_add}>Update</button>
                           </div>
                          
                        </div>
                    </div>
                    <div className='popup-overlay'></div>
                </div>
            )} */}
                </div>
                <div className={selectedItem === 2 ? 'allnotification' : 'allnotification hide'}>
                    <div className='form-group fir_name'>
                        <label className='lable_name'><b>{translate('all_notifications')}</b> </label>
                        <p></p>
                            {cards1.notification && cards1.notification.map((notifi) => (
                            <div key={notifi._id} className='address_list'>
                            <div className='notification_list'>
                                    <div className='notification'>
                                    <img className='noti_img' src={notifi.image} /> 
                                    </div>
                                    <div className='noti_text'> 
                                        <p className='n_text'>{notifi.body} {notifi.extra_info}</p>
                                        {/* <p className='n_date'>14 Feb 2024</p> */}
                                    </div>
                                </div>    
                            </div>
                           
                            ))}
                            
                    </div>
                </div>
        
    </div>
  </div>
  
       
    );
}

export default Profilesidebar;
