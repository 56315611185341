import React from 'react';
import './StarRating.css'; // Import the CSS file for styling

const Product_rating = ({ rating }) => {

  return (
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((star) => (
        <span
          key={star}
          className={star <= rating ? 'star selected' : 'star'} // Apply selected class based on rating
          // Handle click event to update rating (if needed)
        >
          &#9733; {/* Render a star character */}
        </span>
      ))}
      {/* <p>Current Rating: {rating}</p> */}
    </div>
  );
};

export default Product_rating;
