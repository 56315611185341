import React, { useState, useEffect } from "react";
import './Manu.css';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const Menu = () => {
  const navigate = useNavigate();
  const [setcards, setSetcards] = useState([]);
  const [setcards1, setSetcards1] = useState([]);

  const handleDivClick = (id) => {

    localStorage.setItem("category_id", id);
    navigate("/Product", { replace: true });
    window.location.reload();
  };

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/getCategories_menu');
        setSetcards(response.data.categories);
        setSetcards1(response.data.subcat_details);
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCards();
  }, []);

  return (
    <nav className="menu">
      <ul className="menu-list">

      <li className="dropdown" >
            <a href="javascript:void(0);">*All <img className="" alt="Group" src={require('./img/under_areo.png')} /> </a>
              <ul className="dropdown-menu">
                {/* {setcards1.map((products) =>(
                    <li id={products._id} onClick={() => handleDivClick(products._id)}>
                    <a href="javascript:void(0);">{products.product_name}</a>
                  </li>
                      
                ))} */}
                </ul>
        </li>
        {setcards.map((category) => (
          <li className="dropdown" id={category.cat_id}>
            <a href="javascript:void(0);">{category.cat_name} <img className="" alt="Group" src={require('./img/under_areo.png')} /> </a>
            {category.subcat.length > 0 && (
              <ul className="dropdown-menu">
                {category.subcat.map((subcats) => (
                  <li id={subcats._id} onClick={() => handleDivClick(subcats._id)}>
                    <a href="javascript:void(0);">{subcats.sub_cat_name}</a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Menu;
